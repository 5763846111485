<template>
  <div class="WarningIcon">
    <Warning />
  </div>
</template>

<script>
import Warning from '@/assets/svg/warning.svg?inline'

export default {
  name: 'WarningIcon',
  components: {
    Warning,
  },
}
</script>

<style lang="stylus" scoped>
  .WarningIcon
    display block
    box(100%)
    svg
      fill $color_yellow_dark
</style>
