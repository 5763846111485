<template>
  <div class="ImageInspectModal">
    <!-- Image -->
    <div class="ImageWrap">
      <img
        :src="url"
        alt="" />
    </div>

    <!-- Options -->
    <div class="OptionsWrap">
      <div
        v-if="enableReupload"
        class="Option"
        @click="onReuploadClick">
        <CameraIcon />
      </div>
      <div
        v-if="enableDelete"
        class="Option"
        @click="onDeleteClick">
        <BinIcon />
      </div>
      <div
        class="Option Close"
        @click="onCloseClick">
        <CloseIcon />
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close.svg?inline'
import BinIcon from '@/assets/svg/bin.svg?inline'
import CameraIcon from '@/assets/svg/camera.svg?inline'
import { enableBodyScroll, preventBodyScroll } from '@/globals/javascript/_util/util'

export default {
  name: 'ImageInspectModal',
  props: {
    url: {
      type: String,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableReupload: {
      type: Boolean,
      required: false,
      default: false,
    },
    onCustomConfirmDelete: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      maxWidth: window.innerWidth,
    }
  },
  methods: {
    onCloseClick() {
      this.$emit('close')
    },
    onReuploadClick() {
      this.$emit('reupload')
    },
    onDeleteClick() {
      if (!this.onCustomConfirmDelete && !window.confirm(this.mixWB('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_IMAGE'))) {
        return
      }

      if (this.onCustomConfirmDelete && !this.onCustomConfirmDelete()) {
        return
      }

      this.$emit('delete')
    },
    onKeyDown(event) {
      if (event.keyCode === 27) {
        this.onCloseClick()
      }
    },
  },
  components: {
    CloseIcon,
    BinIcon,
    CameraIcon,
  },
  created() {
    preventBodyScroll(true)
    window.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    enableBodyScroll()
    window.removeEventListener('keydown', this.onKeyDown)
  },
}
</script>

<style lang="stylus" scoped>
  .ImageInspectModal
    position fixed
    top 0
    left 0
    box(100%)
    z-index $z_modal
    background-color #000
    flex-center-children()

  .ImageWrap
    width 100vw
    height 100vh
    img
      box(100%)
      object-fit contain
      background-color $color_grey_dark

  .OptionsWrap
    position absolute
    top 0
    right 0
    display flex
    justify-content flex-end
    padding 10px

  .Option
    box(40px)
    margin-left 5px
    background-color rgba($color_grey_light, 0.72)
    padding 10px
    svg
      fill #fff
    &.Close
      padding 12px

</style>
