<template>
  <div
    :class="rootClasses"
    class="MultiImage"
    @click="onClick">
    <div class="TopPadding"/>
    <div class="Inner">
      <img
        v-show="url"
        :src="url"
        ref="Image"
        @load="onImageLoaded" />
      <div
        v-if="isLoading"
        class="ProgressWrap">
        <Circular :size="loadingSize" />
      </div>
      <div
        v-if="failed"
        class="FailedWrap">
        <WarningIcon />
      </div>
      <slot/>
    </div>

    <ImageInspectModal
      v-if="inspectImage.url"
      :url="inspectImage.url"
      :enableDelete="enableDelete"
      :onCustomConfirmDelete="onCustomConfirmDelete"
      :enableReupload="enableReupload"
      @close="onIspectImageClose"
      @delete=onImageDelete
      @reupload=onImageReupload />
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'
import WarningIcon from '@/components/Icons/WarningIcon.vue'
import ImageInspectModal from '@/components/ImageInspectModal.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import { PDFTracker } from '@/globals/javascript/_util/PDFTracker'

export default {
  name: 'MultiImage',
  props: {
    image: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    loadingSize: {
      type: String,
      required: false,
      default: 'small',
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    onCustomConfirmDelete: {
      type: Function,
      required: false,
    },
    enableReupload: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepSquare: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      url: '',
      blobURL: '',
      isLoading: false,
      failed: false,
      inspectImage: {
        url: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'imageUploadList',
    ]),
    rootClasses() {
      return {
        KeepSquare: this.keepSquare,
        FillSpace: !this.keepSquare,
      }
    },
  },
  watch: {
    size(to, from) {
      if (to !== from) {
        this.loadImage()
      }
    },
    image() {
      this.loadImage()
    },
  },
  methods: {
    onImageLoaded() {
      this.isLoading = false
      PDFTracker.loaded(this.getSizePath())
      this.$emit('on-load')
    },
    onTemporaryBlobLoaded({ basePath, blob }) {
      if (this.image.base.path !== basePath) {
        return
      }

      this.blobURL = URL.createObjectURL(blob)
      this.loadImage()
    },
    onImageUploaded({ path }) {
      if (this.image.base.path !== path) {
        return
      }

      if (!this.blobURL) {
        this.loadImage()
      }
    },
    onImageUploadFailed({ path }) {
      if (this.image.base.path !== path) {
        return
      }

      URL.revokeObjectURL(this.blobURL)
      this.blobURL = ''
      this.loadImage()
    },
    getSizePath() {
      const sizeItem = this.mixGetImageSizeItem({ imageObject: this.image, size: this.size })
      return sizeItem ? sizeItem.path : this.image.base.path
    },
    loadImage() {
      // Reset
      this.url = ''
      this.isLoading = true
      this.failed = false

      // Check for temporary blob URL
      if (this.blobURL) {
        this.url = this.blobURL
        return
      }

      // Check for being uploaded
      const uploadItem = this.imageUploadList.find((x) => x.path === this.image.base.path)
      if (uploadItem && ['initial', 'uploading'].includes(uploadItem.status)) {
        return
      }
      if (uploadItem && uploadItem.status === 'failed') {
        this.isLoading = false
        this.failed = true
        return
      }

      // Get requested image
      const path = this.getSizePath()
      PDFTracker.track(path)

      // Load new image
      this.mixGetDownloadURL({ path })
        .then((url) => {
          this.url = url
        })
        .catch(() => {
          this.isLoading = false
          if (!this.blobURL) {
            this.failed = true
          }
          PDFTracker.failed(path)
        })
    },
    onClick() {
      if (!this.preview) {
        return
      }

      if (this.enableReupload && this.failed) {
        const answer = window.confirm(this.mixWB('ARE_YOU_SURE_YOU_WANT_TO_UPLOAD_A_NEW_IMAGE'))
        if (answer) {
          this.onImageReupload()
        }
        return
      }

      if (this.enableDelete) {
        const uploadItem = this.imageUploadList.find((x) => x.path === this.image.base.path)
        if (uploadItem && ['initial', 'uploading'].includes(uploadItem.status)) {
          const answer = window.confirm(this.mixWB('THE_IMAGE_DOES_NOT_APPEAR_TO_HAVE_BEEN_UPLOADED_YET_DO_YOU_WANT_TO_DELETE_IT'))
          if (answer) {
            this.$emit('delete')
          }
          return
        }

        // Check for failed
        if (this.failed) {
          const answer = window.confirm(this.mixWB('THE_IMAGE_DOES_NOT_APPEAR_TO_EXIST_DO_YOU_WANT_TO_DELETE_IT'))
          if (answer) {
            this.$emit('delete')
          }
          return
        }

        // Check for URL
        if (!this.url) {
          const answer = window.confirm(this.mixWB('DOWNLOADING_IMAGE_DO_YOU_WANT_TO_DELETE_IT'))
          if (answer) {
            this.$emit('delete')
          }
          return
        }
      }

      // Show preview
      this.inspectImage.url = this.url
    },
    onIspectImageClose() {
      requestAnimationFrame(() => {
        this.inspectImage.url = ''
      })
    },
    onImageDelete() {
      this.$emit('delete')
    },
    onImageReupload() {
      this.onIspectImageClose()
      this.$emit('reupload')
    },
  },
  components: {
    Circular,
    WarningIcon,
    ImageInspectModal,
  },
  mounted() {
    this.loadImage()
    EventBus.$on('image-loaded', this.onTemporaryBlobLoaded)
    EventBus.$on('image-uploaded', this.onImageUploaded)
    EventBus.$on('image-upload-failed', this.onImageUploadFailed)
  },
  destroyed() {
    EventBus.$off('image-loaded', this.onTemporaryBlobLoaded)
    EventBus.$off('image-uploaded', this.onImageUploaded)
    EventBus.$off('image-upload-failed', this.onImageUploadFailed)

    if (this.blobURL) {
      URL.revokeObjectURL(this.blobURL)
    }

    const path = this.getSizePath()
    PDFTracker.unTrack(path)
  },
}
</script>

<style lang="stylus" scoped>
  .MultiImage
    position relative
    width 100%
    &.KeepSquare
      overflow hidden
    &.FillSpace
      height 100%

  .TopPadding
    width 100%
    padding-top 100%
    .FillSpace &
      display none

  .Inner
    box(100%)
    .KeepSquare &
      position absolute
      top 0
      left 0
      img
        box(100%)
        object-fit contain
        background-color $color_grey_light
    .FillSpace &
      img
        max-width 100%
        max-height 100%

  .ProgressWrap
    box(100%)
    position absolute
    top 0
    left 0

  .FailedWrap
    box(100%)
    padding 25%
    position absolute
    top 0
    left 0
    border 1px solid $color_grey_lighter
</style>
