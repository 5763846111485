const tracked = {}

/**
 * Utility class to help track state of loaded assets.
 *
 * The PDF generator in cloud, waits until the `isAllLoaded` returns true. All images on a page,
 * will be tracked through the `PDFTracker.track(path)` function.
 */
export class PDFTracker {
  static track = (id) => {
    if (typeof tracked[id] === 'undefined') {
      tracked[id] = null
    }
  }

  static unTrack = (id) => {
    delete tracked[id]
  }

  static loaded = (id) => {
    tracked[id] = true
  }

  static failed = (id) => {
    tracked[id] = false
  }

  static isAllLoaded = () => Object.values(tracked).every((loaded) => loaded !== null)
}

// Should be globally available so the cloud function can evaluate it.
window.isAllLoaded = PDFTracker.isAllLoaded
